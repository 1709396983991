import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import axios from "axios";
import { BiLogOutCircle } from "react-icons/bi";
import { FaEye, FaUserPlus } from "react-icons/fa6";
import { IoMdCloseCircle } from "react-icons/io";
import { FaTrashAlt } from "react-icons/fa";
import Swal from 'sweetalert2'
import Loader from './Loader.gif';

import "bootstrap/dist/css/bootstrap.css";
import "../App.css";

// import puppeteer from 'puppeteer';

const Dashboard = () => {
  const [updatedUser, setUpdatedUser] = useState({});
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [invoicePDFUrl, setInvoicePDFUrl] = useState("")

  const [loading, setLoading] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_name");
    localStorage.removeItem("user_email");
    localStorage.removeItem("user_role");
    window.location.href = "/";
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const accessToken = localStorage.getItem("token");

        if (!accessToken) {
          window.location.href = "/";
        }

        const response = await axios.get("https://api.tpdetector.com/api/users", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setUsers(response.data.data);
      } catch (error) {
        window.location.href = "/";
        console.log(error);
      }
    };

    fetchUserData();
  }, []);

  const fetchInvoices = async () => {
    try {
      const response = await axios.get('https://api.tpdetector.com/api/invoices');
      const inv = response.data.reverse()
      setInvoices(inv);

    } catch (error) {
      setError('Failed to fetch invoices');
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleUpdate = (userId) => {
    const userToUpdate = users.find((user) => user._id === userId);
    setUpdatedUser(userToUpdate);
    setIsEditPopupOpen(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUpdatedUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const saveUpdatedUser = () => {
    const userIndex = users.findIndex((user) => user._id === updatedUser._id);
    const updatedUsers = [...users];
    updatedUsers[userIndex] = updatedUser;

    axios
      .put("https://api.tpdetector.com/api/users/" + updatedUser._id, updatedUser)
      .then((response) => {
        setUsers(updatedUsers);
        setIsEditPopupOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDelete = (userId) => {
    console.log("Deleting user with ID:", userId);
    fetch(`https://api.tpdetector.com/api/users/${userId}`, { method: "DELETE" })
      .then((response) => {
        if (response.ok) {
          const updatedUsers = users.filter((user) => user._id !== userId);
          setUsers(updatedUsers);
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
      });
  };

  const tableHeaderStyle = {
    backgroundColor: "#f2f2f2",
    padding: "8px",
    border: "1px solid #ddd",
  };

  const tableCellStyle = {
    padding: "8px",
    border: "1px solid #ddd",
  };
  
  
  const getFormattedDate = (unformattedDate) => {
    const monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const dt = new Date(unformattedDate);
    
    const date = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate()
    let hours = dt.getHours() % 12
    hours = hours < 10 ? "0" + hours : hours
    const minutes = dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes()
    const seconds = dt.getSeconds() < 10 ? "0" + dt.getSeconds() : dt.getSeconds()
    const meridian = dt.getHours() >= 12 ? "PM" : "AM"
    
    const newFormattedDate =  date + "-" + monthList[dt.getMonth()] + "-" + dt.getFullYear() + "   " + hours  + ":" + minutes + ":" + seconds + " " + meridian;
    return newFormattedDate
  }




  
  const viewInvoice = (invoiceId) => {
    // Show Loading Screen
    setLoading(true);


    fetch(`https://api.tpdetector.com/api/invoice/${invoiceId}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob(); 
      })
      .then(blob => {
        setInvoicePDFUrl(window.URL.createObjectURL(blob))
      })
      .catch(error => {
        console.error('Error fetching or displaying invoice pdf:', error);
      })

      .finally(() => {
        // Hide Loading Screen
        setLoading(false);
      });

  }

  const deleteInvoice = (invoiceId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`https://api.tpdetector.com/api/invoice_delete/${invoiceId}`, {
            method: "DELETE"
          });
          if (!response.ok) {
            throw new Error("Failed to delete invoice");
          }
          // Remove the deleted invoice from the DOM
          const deletedInvoice = document.getElementById(`invoice-${invoiceId}`);
          if (deletedInvoice) {
            deletedInvoice.remove();
          }
          fetchInvoices();
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success"
          });
        } catch (error) {
          console.error(error);
          Swal.fire({
            title: "Error",
            text: "Failed to delete invoice",
            icon: "error"
          });
        }
      }
    });
  };
  

  
  return (
    <div className="container d-block">
      <div className="my-4 d-flex justify-content-between align-items-center">

        <Link to="/Createnewuser" className="custom-button dark-button">Create new user <FaUserPlus  /></Link>
        {/* <h3>
      Lets go for a <FaBeer />?
    </h3> */}
        <button
          className="custom-button logout-button"
          style={{
            // position: "fixed",
            
            left: "80%",
            transform: "translateX(-20%)",
          }}
          onClick={handleLogout}
        >
          Logout <BiLogOutCircle />

        </button>
      </div>

      {
        invoicePDFUrl && (
          <div className="invoice-pdf-container">
            <embed  src={invoicePDFUrl} type="application/pdf" />
            <div onClick={()=>{setInvoicePDFUrl("")}} className="close-btn mb-auto"><IoMdCloseCircle  /> <small>close</small></div>
          </div>
        )
      }

      <div className="user-list-container">
      <h2><b>User List</b></h2>
     
        <div className="table-container">
          {isEditPopupOpen && (
            <div className="edit-popup">
              <input
                type="text"
                name="name"
                value={updatedUser.name || ""}
                onChange={handleInputChange}
              />
              <input
                type="text"
                name="email"
                value={updatedUser.email || ""}
                onChange={handleInputChange}
              />
              <input
                type="text"
                name="password"
                value={updatedUser.password || ""}
                onChange={handleInputChange}
              />
              <select
                name="role"
                value={updatedUser.role || ""}
                onChange={handleInputChange}
              >
                <option value="" disabled selected>
                  Select Role
                </option>
                <option value="Admin">Admin</option>
                <option value="User">User</option>
              </select>
              <input
                type="Number"
                name="wordCount"
                value={updatedUser.wordCount || ""}
                onChange={handleInputChange}
              />
              <button onClick={saveUpdatedUser}>Save</button>
              <button onClick={() => setIsEditPopupOpen(false)}>Close</button>
            </div>
          )}
     
          <table className="table table-striped">
            <thead>
              <tr>
                  <th  style={tableHeaderStyle}>#</th>
                  <th style={tableHeaderStyle}>Name</th>
                  <th style={tableHeaderStyle}>Email</th>
                  <th style={tableHeaderStyle}>Role</th>
                  <th style={tableHeaderStyle}>Word_Count</th>
                  <th style={tableHeaderStyle}>Update</th>
                  <th style={tableHeaderStyle}>Delete</th>
              </tr>
            </thead>
            <tbody>
              
              {users.map((user,index) => (
                <tr key={user._id}>
                   <td>{index + 1}</td>
                  <td style={tableCellStyle}>{user.name}</td>
                  <td style={tableCellStyle}>{user.email}</td>
                  <td style={tableCellStyle}>{user.role}</td>
                  <td style={tableCellStyle}>{user.wordCount}</td>
                  <td>
                    <button onClick={() => handleUpdate(user._id)}>
                      Update
                    </button>
                  </td>
                  <td>
                    <button onClick={() => handleDelete(user._id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
       
       
 
    <div className="container d-block my-5">
      <h2 className="mb-3"><b>Registered Invoice Details</b>
      <h6><a href="javascript:void(0);" onClick={togglePopup}>{isOpen ? "Hide" : "Show"}</a></h6>
      </h2>
      {isOpen && (
      /* {true && ( */
     <div className='table-container my-2'>
     <table className="table table-striped invoice-table ">
        <thead>
          <tr>
            <th>#</th>
            <th>Invoice ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th># Words</th>
            <th>Title</th>
            <th>Amount</th>
            <th>Actions</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
    {invoices.map((invoice, index) => (
      <tr key={invoice._id}>
            <td>{invoices.length - index}</td> 
            <td style={{ whiteSpace: 'nowrap' }}>{invoice.customerId}</td>
            <td>{invoice.name}</td>
            <td>{invoice.email}</td>
            <td>{invoice.phone}</td>
            <td>{invoice.numberOfWords}</td>
            <td>{invoice.title}</td>
            <td className="text-end" style={{ whiteSpace: 'nowrap' }}>{invoice.registrationFee} {invoice.currency}</td>
            <td className="text-center">
            {loading ? (
  
    <img src={Loader} alt=""  style={{height:'20px'}}/>
  
) : (
  <div>
    <FaEye
      onClick={() => viewInvoice(invoice._id)}
      title="View"
      style={{ cursor: 'pointer', color: 'green' }}
    />
  </div>
)}
              <div><FaTrashAlt   onClick={()=>{deleteInvoice(invoice._id)}} title="delete" style={{cursor: 'pointer', color: '#d00'}}/></div>
            </td>
            <td style={{fontSize: '80%', whiteSpace: 'nowrap'}}>{getFormattedDate(invoice.createdAt)}</td>
        </tr>
    ))}
</tbody>


      </table></div> 
  )}
  </div>
</div>


        
      </div>
  );
};

export default Dashboard;
